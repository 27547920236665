exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-base-js": () => import("./../../../src/pages/base.js" /* webpackChunkName: "component---src-pages-base-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-conheca-o-projeto-bertoni-elos-js": () => import("./../../../src/pages/conheca-o-projeto-bertoni-elos.js" /* webpackChunkName: "component---src-pages-conheca-o-projeto-bertoni-elos-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-elos-js": () => import("./../../../src/pages/elos.js" /* webpackChunkName: "component---src-pages-elos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leads-js": () => import("./../../../src/pages/leads.js" /* webpackChunkName: "component---src-pages-leads-js" */),
  "component---src-pages-origem-js": () => import("./../../../src/pages/origem.js" /* webpackChunkName: "component---src-pages-origem-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-sucesso-js": () => import("./../../../src/pages/sucesso.js" /* webpackChunkName: "component---src-pages-sucesso-js" */),
  "component---src-pages-turmas-js": () => import("./../../../src/pages/turmas.js" /* webpackChunkName: "component---src-pages-turmas-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

